import { useMemo } from 'react'
import clsx from 'clsx'

import PricingPlan from 'components/PricingPlan'
import SubscriptionTimesSelector from 'components/SubscriptionTimesSelector'

import { ISubscriptionPlanSelectionProps } from '../types'

const SubscriptionPlanSelection = ({
  selectedTime,
  setSelectedTime,
  planSlice,
  selectedPlanType,
  selectPlan,
  subscriptionPrice
}: ISubscriptionPlanSelectionProps) => {
  const subscriptionToShow = useMemo(
    () =>
      planSlice?.filter((plan) => !!subscriptionPrice[plan.primary.planType]) ??
      [],
    [planSlice, subscriptionPrice]
  )
  return (
    <>
      <SubscriptionTimesSelector
        className="mb-3 md:mb-5"
        selectedTime={selectedTime}
        onSelect={setSelectedTime}
        hideTrial
      />
      <div
        className={clsx(
          `mb-5 flex max-w-5xl gap-10 text-left text-black max-md:flex-col`,
          subscriptionToShow.length >= 3 && `max-lg:flex-col`
        )}
      >
        {subscriptionToShow.map((plan, index) => (
          <div className="flex flex-1" data-cy={`plan-${index}`} key={index}>
            <PricingPlan
              startNowButton={false}
              tiny
              active={plan.primary.planType === selectedPlanType}
              planInfo={plan}
              time={selectedTime}
              price={subscriptionPrice[plan.primary.planType]?.price ?? 0}
              showSelector
              className="w-full"
              onClick={() => {
                selectPlan(plan.primary.planType)
              }}
            />
          </div>
        ))}
      </div>
    </>
  )
}
export default SubscriptionPlanSelection
