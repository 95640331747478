import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'

import ConfirmationModal from '../ConfirmationModal'

import { IDowngradingProps } from './types'

const DowngradingModal = ({
  isRestricted,
  onSubmit,
  onClose,
  targetPlan,
  ...props
}: IDowngradingProps) => {
  const { t } = useTranslation(['crm', 'actions'])
  const onSubmitHandler = useCallback(() => {
    onSubmit?.()
    onClose?.()
  }, [onClose, onSubmit])

  return (
    <ConfirmationModal
      {...props}
      onClose={onClose}
      onSubmit={onSubmitHandler}
      tSubmit={{ key: 'change', ns: 'actions' }}
      tCancel={{ key: 'decline', ns: 'actions' }}
    >
      <div className=" max-w-4xl text-center ">
        {isRestricted ? (
          t(`org.subscription.${targetPlan}.errorMessage`, { ns: 'crm' })
        ) : (
          <div className="text-left">
            {t(`org.subscription.${targetPlan}.switch`, { ns: 'crm' })}
            <ul className={`list-inside list-["-_"] `}>
              <li>
                {t(`org.subscription.${targetPlan}.restriction1`, {
                  ns: 'crm'
                })}
              </li>
              <li>
                {t(`org.subscription.${targetPlan}.restriction2`, {
                  ns: 'crm'
                })}
              </li>
              <li>
                {t(`org.subscription.${targetPlan}.restriction3`, {
                  ns: 'crm'
                })}
              </li>
            </ul>
          </div>
        )}

        <div className="mt-5">
          {t('org.subscription.confirmChange2', { ns: 'crm' })}
        </div>
      </div>
    </ConfirmationModal>
  )
}
export default DowngradingModal
