import { DowngradingModal } from 'components/modals'
import ImmediatelyChangeSubscription from 'components/modals/ImmediatelyChangeSubscription'

import { SubscriptionNames } from 'constants/subscription'

import ConfirmationModal from '../ConfirmationModal'

import { PaymentMethodSelection, SubscriptionPlanSelection } from './components'
import { IChooseSubscriptionModalProps } from './types'
import useChooseSubscriptionModal, {
  SubscriptionModalSteps
} from './useChooseSubscriptionModal'

const ChooseSubscriptionModal = ({
  isOpen,
  isLoading,
  nextSubscriptionPlan,
  nextSubscriptionPeriod,
  onClose,
  afterSubmit,
  isChangeSubscription
}: IChooseSubscriptionModalProps) => {
  const {
    selectedPlanType,
    subscriptionPrice,
    isSaving,
    planSlice,
    selectedTime,
    showConfirmation,
    isRestricted,
    setShowConfirmation,
    setSelectedTime,
    selectPlan,
    step,
    handleCancel,
    handleSubmit,
    paymentType,
    setPaymentType,
    textSubmit,
    textCancel,
    secondaryModalsSubmit
  } = useChooseSubscriptionModal({
    onClose,
    nextSubscriptionPlan,
    nextSubscriptionPeriod,
    afterSubmit,
    isChangeSubscription
  })

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isLoading || isSaving}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      tSubmit={textSubmit}
      tCancel={textCancel}
      disabled={!selectedPlanType}
      buttonsClassName="flex-row-reverse justify-between mx-5 sm:mx-0"
    >
      {step == SubscriptionModalSteps.SelectPlan && (
        <SubscriptionPlanSelection
          selectedPlanType={selectedPlanType}
          planSlice={planSlice}
          selectPlan={selectPlan}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
          subscriptionPrice={subscriptionPrice}
        />
      )}

      {step == SubscriptionModalSteps.SelectMethod && (
        <PaymentMethodSelection
          paymentType={paymentType}
          setPaymentType={setPaymentType}
        />
      )}

      <ImmediatelyChangeSubscription
        isOpen={showConfirmation === 'toPro'}
        onClose={() => setShowConfirmation(null)}
        onSubmit={secondaryModalsSubmit}
      />
      <DowngradingModal
        isOpen={showConfirmation === 'toBasic'}
        targetPlan={SubscriptionNames.Basic}
        onClose={() => setShowConfirmation(null)}
        onSubmit={secondaryModalsSubmit}
        isRestricted={isRestricted(SubscriptionNames.Basic)}
      />
      <DowngradingModal
        isOpen={showConfirmation === 'toMini'}
        targetPlan={SubscriptionNames.Mini}
        onClose={() => setShowConfirmation(null)}
        onSubmit={secondaryModalsSubmit}
        isRestricted={isRestricted(SubscriptionNames.Mini)}
      />
    </ConfirmationModal>
  )
}
export default ChooseSubscriptionModal
