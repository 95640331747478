import {
  AdvertTranslation,
  AdvertVisibilityType,
  CreationStep,
  EmploymentStatus
} from 'enums'
import { z } from 'zod'

import { ListIndustrySchema } from '../schemas/listItem.schema'
import { advertAddressValidator } from './addressValidator'
import { responsibilitiesItem } from './adStepValidator'
import { adDescriptionValidator } from './descriptionValidator'
import educationValidator from './educationValidator'
import translationValidator from './fieldLanguageValidator'
import { languageLevelValidator } from './languageValidator'

const competencyValidator = z.object({
  id: z.string().uuid().optional(),
  skillId: z.string().uuid(),
  skill: z.string().optional(),
  yearsOfExperience: z.number().int().min(0),
  priority: z.string(),
  value: z.string().optional(),
  label: z.string().optional()
})

const advertValidatorStd = z.object({
  id: z.string().uuid().optional(),
  organisationId: z.string().uuid(),
  descriptions: adDescriptionValidator.array().min(0),
  address: advertAddressValidator.nullable(),
  hiringForClient: z.boolean().optional(),
  creationStep: z.nativeEnum(CreationStep),

  competencies: competencyValidator.array(),
  responsibilities: responsibilitiesItem.array(),
  languages: languageLevelValidator.array(),
  salaryFrom: z.number().int().min(100),
  salaryTo: z.number().int().min(0),
  bonuses: z.string().array(),
  visibilityType: z.nativeEnum(AdvertVisibilityType),
  employmentStatus: z.nativeEnum(EmploymentStatus),
  drivingLicence: z.boolean().nullable(),
  translation: z.nativeEnum(AdvertTranslation).nullable().optional(),
  educations: educationValidator.array(),
  industries: ListIndustrySchema.array(),
  positionId: z.string().uuid().nullable(),
  positionLevelId: z.string().uuid().nullable(),
  industryId: z.string().uuid(),
  businessFunctionId: z.string().uuid(),
  contractTypeId: z.string().uuid(),
  trainingBudgetId: z.string().uuid().nullable(),
  remoteWorkTypeId: z.string().uuid(),

  positionTexts: translationValidator.array(),
  titles: translationValidator.array()
})

const advertValidator = advertValidatorStd.superRefine((advert, ctx) => {
  if (
    advert.salaryFrom &&
    advert.salaryTo &&
    advert.salaryFrom > advert.salaryTo
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'custom.salary'
    })
  }
})

export type Advert = z.infer<typeof advertValidator>

export { advertValidator, competencyValidator }
