import { NotificationType } from 'enums'

import AutoApplyModal from './modals/AutoApplyModal'
import FillUserIndustriesNotificationModal from './modals/FillUserIndustriesNotification'
import PhoneNumberVisibleModal from './modals/PhoneNumberVisibleModal'
import ProhibitNavigationModal from './modals/ProhibitNavigationModal'
import { ICandidateNotificationModalProps } from './types'

const CandidateNotificationsModal = ({
  notifications,
  isLoading,
  onClose
}: ICandidateNotificationModalProps) => {
  return (
    <>
      <FillUserIndustriesNotificationModal
        isOpen={notifications === NotificationType.UserIndustriesSpecification}
        isLoading={isLoading}
        onClose={onClose}
      />
      <AutoApplyModal
        isOpen={notifications === NotificationType.AutoApply}
        onClose={onClose}
        isLoading={isLoading}
      />
      <PhoneNumberVisibleModal
        isOpen={notifications === NotificationType.PhoneNowVisibleOnCV}
        isLoading={isLoading}
        onClose={onClose}
      />
      <ProhibitNavigationModal />
    </>
  )
}

export default CandidateNotificationsModal
