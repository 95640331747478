import { SubscriptionNames } from 'constants/subscription'

export type SubscriptionNamesVariation =
  | SubscriptionNames.Basic
  | SubscriptionNames.Mini

export const SubscriptionMaxAdvertRestriction: Record<
  SubscriptionNamesVariation,
  number
> = {
  [SubscriptionNames.Basic]: 3,
  [SubscriptionNames.Mini]: 1
}
